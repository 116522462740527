<template>
  <div v-cloak class="modal small" v-body-scroll-lock="true">
    <div class="modal-content">
      <div @click="close" class="menu-close" data-dismiss="modal" aria-label="Close">
        <span class="menu-close-icon" />
      </div>
      <div class="modal-content-section">
        <h3 class="modal-content-title" v-html="title" />
        <h4 class="modal-content-subtitle" v-html="subtitle"/>
        <form id="confirm-form" action="" @submit.prevent="send">
          <div class="modal-content-inner">
            <form-group label="2FA Code" :error="error">
              <code-input v-model="code" autofocus />
            </form-group>
          </div>
          <div class="modal-content-bottom-panel">
            <button type="submit" :class="buttonClass" :disabled="process">
              {{ buttonName }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import FormGroup from "@/libs/forms/FormGroup";
import CodeInput from "@/libs/CodeInput";

import get from 'lodash/get'
import has from 'lodash/has'
import union from 'lodash/union'

export default {
  name: 'ConfirmModal',
  components: {
    FormGroup,
    CodeInput
  },
  props: {
    submit: {
      type: Function
    },
    /**
     * Code param as array or string:
     * @example: ['code', 'id', ...] or 'code'
     * @default: code
     */
    codeParam: {
      type: [Array, String],
      default: 'code'
    },
    isSuccess: {
      type: Function,
      default: (data) => {
        return Array.isArray(data) && data.length === 0
      }
    },
    title: {
      type: String,
      default: 'Two-Factor authentication'
    },
    subtitle: {
      type: String,
      default: 'Please enter the code from Google Authenticator.'
    },
    buttonName: {
      type: String,
      default: 'Confirm'
    },
    buttonClass: {
      type: Array,
      default: () => ['default-button', 'button-block']
    },
    beforeClose: {
      type: Function,
      default: () => {}
    },
    timeout: {
      type: Number,
      default: 7000
    }
  },
  data () {
    return {
      code: '',
      error: [],
      process: false
    }
  },
  watch: {
    'error' (newValue) {
      if (!!newValue.length && this.code.length === 6) {
        this.code = ''
      }
    },
  },
  methods: {
    close () {
      this.beforeClose()
      this.$emit('close')
    },
    send () {
      this.process = true

      this.submit ({code: this.code})
        .then(response => {

          if (response && response.data) {
            if (this.isSuccess(response.data)) {
              this.close()
            } else {
              let responseErrorsKeys = union(this.getCodeParam(), Object.keys(response.data))
              for (let key of responseErrorsKeys) {
                if (has(response.data, key)) {
                  this.setError(get(response.data, key, []))
                  break;
                }
              }
            }
          }

          return response
        }).catch(reason => {
          this.setError(get(reason, 'response.data.message'))

        }).finally(() => {
          this.process = false
        })
    },
    setError (message) {
      Array.isArray(message)
          ? this.error = message
          : this.error.push(message)

      setTimeout(() => {
        this.error = []
      }, this.timeout)
    },

    /**
     * Get list of prioritised code params
     *
     * @returns {string[]}
     */
    getCodeParam () {
      if (Array.isArray(this.codeParam)) {
        return this.codeParam
      } else {
        return [this.codeParam]
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .modal {
    max-width: 530px;

    &-content {
      &-title {
        margin-bottom: 10px !important;
      }
      &-subtitle {
        margin: 0;
      }
      &-inner {
        margin-top: 30px;
      }
    }
  }

  .button-block {
    display: block;
    width: 100%;
  }

  .button-red {
    background-color: #EA4C17;
    border-color: #EA4C17;
    color: #FFF;
  }
</style>
